import baseApi from '@/services/baseApi';
import { GUID, IPageResponse } from '@/types/common.types';
import TAG_TYPES from '@/utils/constants/tagTypes';
import {
  ICreateTrackingNoteClassesRequest,
  ICreateTrackingNoteRequest,
  ITrackingNote,
  ITrackingNoteParams,
  IUpdateTrackingNoteRequest,
} from '@/services/trackingNote/types';

export const trackingNoteApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    trackingNotes: builder.query<
      IPageResponse<ITrackingNote>,
      ITrackingNoteParams
    >({
      query: ({ campusID, ...params }) => ({
        url: `student-tracking-notes`,
        params,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: [TAG_TYPES.TRACKING_NOTE],
    }),
    trackingNoteById: builder.query<
      ITrackingNote,
      { campusID?: GUID; trackingNoteID: GUID }
    >({
      query: ({ campusID, trackingNoteID }) => ({
        url: `student-tracking-notes/${trackingNoteID}`,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: (result, error, args) => [
        { type: TAG_TYPES.TRACKING_NOTE, id: args.campusID },
      ],
    }),

    createTrackingNote: builder.mutation<
      ITrackingNote,
      ICreateTrackingNoteRequest
    >({
      query: ({ campusID, ...params }) => ({
        url: `student-tracking-notes`,
        method: 'POST',
        data: params,
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.TRACKING_NOTE }],
    }),

    updateTrackingNote: builder.mutation<
      ITrackingNote,
      IUpdateTrackingNoteRequest
    >({
      query: ({ campusID, trackingNoteID, ...params }) => ({
        url: `student-tracking-notes/${trackingNoteID}`,
        method: 'PUT',
        data: params,
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.TRACKING_NOTE }],
    }),
    deleteTrackingNote: builder.mutation<
      unknown,
      { campusID?: GUID; trackingNoteID: GUID }
    >({
      query: ({ campusID, trackingNoteID }) => ({
        url: `student-tracking-notes/${trackingNoteID}`,
        method: 'DELETE',
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.TRACKING_NOTE }],
    }),

    updateTrackingNoteStatus: builder.mutation<
      ITrackingNote,
      { trackingNoteID: GUID; isActive: boolean }
    >({
      query: ({ isActive, trackingNoteID }) => ({
        url: `student-tracking-notes/${trackingNoteID}/status`,
        method: 'PATCH',
        data: { isActive },
      }),
      invalidatesTags: [{ type: TAG_TYPES.TRACKING_NOTE }],
    }),

    createClassTrackingNote: builder.mutation<
      ITrackingNote,
      ICreateTrackingNoteClassesRequest
    >({
      query: ({ campusID, students, ...params }) => ({
        url: 'student-tracking-notes/batch',
        method: 'POST',
        data: { students, ...params },
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.TRACKING_NOTE }],
    }),
  }),
});

export const {
  useTrackingNotesQuery,
  useLazyTrackingNoteByIdQuery,
  useCreateTrackingNoteMutation,
  useUpdateTrackingNoteMutation,
  useDeleteTrackingNoteMutation,
  useUpdateTrackingNoteStatusMutation,
  useCreateClassTrackingNoteMutation,
} = trackingNoteApi;
